export default {
  namespaced: true,
  state: {
    parametro: '2022.1',
    acordo: {},
    operacoes: [],
    parametros_contato: null,
    parcelas: [],
    parcelasOperacoes: [],
    simulacao: false,
    status: '',
    formaPagamento: null,
    parametroDefault: {},
    credor: {},
    cliente: {},
  },
  getters: {},
  mutations: {
    setProposta(state, data) {
      const { key, value } = data

      state[key] = value
    },
  },
  actions: {},
}
