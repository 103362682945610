import Vue from 'vue'
import Vuex from 'vuex'
// Modules
import app from './app'
import appConfig from './app-config'
import auth from './auth'
import eventos from './negociacao/eventos'
import negociacao from './negociacao/negociacao'
import verticalMenu from './vertical-menu'
import negociar from './negociacao/negociar'
import proposta from './negociacao/proposta'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    eventos,
    negociacao,
    negociar,
    proposta,
  },
  strict: process.env.DEV,
})
