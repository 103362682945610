import axios from 'axios'
import router from '@/router/index'

const instance = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
})

instance.defaults.headers.common.Authorization = `Bearer ${localStorage.token}`

instance.interceptors.response.use(response => response, error => {
  if (error.response.status === 401) {
    localStorage.removeItem('token')
    localStorage.removeItem('expirationDate')
    localStorage.removeItem('userId')
    localStorage.removeItem('acesso_id')
    localStorage.removeItem('funcao')
    localStorage.removeItem('status')
    localStorage.removeItem('status_complemento')
    localStorage.removeItem('time_connected')
    localStorage.removeItem('phone_connected')
    localStorage.removeItem('cpf_connected')
    localStorage.removeItem('userData')
    router.replace('/login')
  }
  return Promise.reject(error)
})

export default instance
