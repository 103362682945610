import firebase from 'firebase/app'

import 'firebase/database'

const firebaseConfig = {
  apiKey: 'AIzaSyC5UCKWS6bPTKlME45yjSvlWNYfhqwg2Kc',
  authDomain: 'vozchat.firebaseapp.com',
  databaseURL: 'https://vozchat-default-rtdb.firebaseio.com',
  projectId: 'vozchat',
  storageBucket: 'vozchat.appspot.com',
  messagingSenderId: '586568389541',
  appId: '1:586568389541:web:8e792baa0a3d7cd2e9ce4c',
  measurementId: 'G-32228JC6R6',
}

firebase.initializeApp(firebaseConfig)

const database = firebase.database()

export default database
