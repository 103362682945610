/* eslint-disable no-unused-vars */
import api from '@/../axios-auth'
// import router from '@/router/index'

export default {
  namespaced: true,
  state: {
    eventos: [],
  },
  getters: {
    listEvents(state) {
      return state.eventos
    },
  },
  mutations: {
    updateEvents(state, eventos) {
      state.eventos = eventos
    },
  },
  actions: {
    async getEvent({ commit }, clienteId) {
      api.get(`api/v1/clientes/event/${clienteId}`).then(async res => {
        const { eventos } = res.data
        if (eventos) {
          commit('updateEvents', eventos)
        }
      })
    },
    async createEvent({ commit }, data) {
      await api.post('api/v1/eventos/store', data).then(res => {
        //
      })
    },
  },
}
