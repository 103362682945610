const routes = [
  {
    path: '/chat/gerenciamento',
    name: 'chat-gerenciamento',
    component: () => import('@/views/apps/chat/GerenciamentoChat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },
  {
    path: '/apps/chat',
    name: 'apps-chat',
    component: () => import('@/views/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
    },
    beforeEnter(to, from, next) {
      if (localStorage.getItem('token')) {
        next({ name: 'home' })
      } else {
        next()
      }
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
  {
    path: '/Dashboard',
    name: 'home',
    component: () => import('@/views/dashboard/GeneralDash.vue'),
  },
  {
    path: '/backoffice',
    name: 'backoffice',
    component: () => import('@/views/backoffice/dashboard/BackofficeDash.vue'),
  },
  {
    path: '/coordenador',
    name: 'coordenador.dash',
    component: () => import('@/views/dashboard/DashboardCoordenador.vue'),
  },
  {
    path: '/supervisor',
    name: 'supervisor.dash',
    component: () => import('@/views/cobranca/supervisor/DashboardSupervisor.vue'),
  },
  {
    path: '/cadastrar/executivo',
    name: 'cadastrar.executivo',
    component: () => import('@/views/cobranca/supervisor/CadastrarExecutivo.vue'),
  },
  {
    path: '/executivo',
    name: 'executivo.dash',
    component: () => import('@/views/cobranca/dashboard/Dash.vue'),
  },
  {
    path: '/cobranca/dashboard',
    name: 'cobranca.dash',
    component: () => import('@/views/cobranca/dashboard/DashboardCobranca.vue'),
  },
  {
    path: '/negociacao',
    name: 'negociacao',
    component: () => import('@/views/cobranca/negociacao/NegociacaoHome.vue'),
  },
  {
    path: '/negociacao/:id',
    name: 'negociacao-cliente',
    component: () => import('@/views/cobranca/negociacao/NegociacaoCliente.vue'),
  },
  {
    path: '/acompanhamento',
    name: 'acompanhamento',
    component: () => import('@/views/cobranca/Acompanhamento/Acompanhamento.vue'),
  },
  {
    path: '/acordos',
    name: 'acordos',
    component: () => import('@/views/cobranca/acordos/AcordosHome.vue'),
  },
  {
    path: '/parcelas/:id',
    name: 'parcelas-acordo',
    component: () => import('@/views/cobranca/acordos/ParcelasAcordo.vue'),
  },
  {
    path: '/parcelas',
    name: 'parcelas',
    component: () => import('@/views/cobranca/parcelas/Parcelas.vue'),
  },
  {
    path: '/campanha/manual',
    name: 'campanha.manual',
    component: () => import('@/views/backoffice/campanha/CampanhaManualExecutivo.vue'),
  },
  {
    path: '/convenio/',
    name: 'financeiro.convenio',
    component: () => import('@/views/financeiro/convenio/ConvenioHome.vue'),
  },

  {
    path: '/clientes',
    name: 'clientes',
    component: () => import('@/views/backoffice/clientes/ClienteList.vue'),
  },
  {
    path: '/cliente/view/:id',
    name: 'cliente-view',
    component: () => import('@/views/backoffice/clientes/ClienteSettings.vue'),
  },
  {
    path: '/cliente/edit/:id',
    name: 'cliente-edit',
    component: () => import('@/views/backoffice/clientes/ClienteEdit.vue'),
  },
  {
    path: '/credores',
    name: 'credores',
    component: () => import('@/views/backoffice/credores/CredoresHome.vue'),
  },
  {
    path: '/devolucao',
    name: 'devolucao',
    component: () => import('@/views/backoffice/devolucao/DevolucaoHome.vue'),
  },
  {
    path: '/devolucao/create',
    name: 'devolucao-create',
    component: () => import('@/views/backoffice/devolucao/CreateDevolucao.vue'),
  },
  {
    path: '/devolucao/edit/:id',
    name: 'devolucao-edit',
    component: () => import('@/views/backoffice/devolucao/DevolucaoEdit.vue'),
  },
  {
    path: '/operacao',
    name: 'operacao',
    component: () => import('@/views/backoffice/operacao/OperacaoHome.vue'),
  },
  {
    path: '/operacao/edit/:id',
    name: 'operacao-edit',
    component: () => import('@/views/backoffice/operacao/OperacaoHome.vue'),
  },
  {
    path: '/remessas',
    name: 'remessas',
    component: () => import('@/views/backoffice/remessa/RemessaHome.vue'),
  },
  {
    path: '/remessa/view/:id',
    name: 'remessa-view',
    component: () => import('@/views/backoffice/remessa/RemessaView.vue'),
  },
  {
    path: '/remessas/edit/:id',
    name: 'remessas-edit',
    component: () => import('@/views/backoffice/remessa/RemessaHome.vue'),
  },
  {
    path: '/campanhas',
    name: 'campanhas',
    component: () => import('@/views/backoffice/campanha/CampanhasList.vue'),
  },
  {
    path: '/campanhas/view/:id',
    name: 'campanhas.view',
    component: () => import('@/views/backoffice/campanha/CampanhaView.vue'),
  },
  {
    path: '/credor/view/:id',
    name: 'credor-view',
    component: () => import('@/views/backoffice/credores/CredoresSettings.vue'),
  },
  {
    path: '/credor/edit/:id',
    name: 'credor-edit',
    component: () => import('@/views/backoffice/credores/CredorEdit.vue'),
  },
  {
    path: '/importacao',
    name: 'importacao',
    component: () => import('@/views/backoffice/importacao/ImportacaoHome.vue'),
  },
  {
    path: '/insucessos',
    name: 'insucessos',
    component: () => import('@/views/backoffice/insucessos/InsucessosHome.vue'),
  },
  {
    path: '/regua',
    name: 'regua',
    component: () => import('@/views/backoffice/regua/ReguaHome.vue'),
  },
  {
    path: '/regua/view/:id',
    name: 'regua.view',
    component: () => import('@/views/backoffice/regua/ReguaView.vue'),
  },
  {
    path: '/roteiro',
    name: 'roteiro',
    component: () => import('@/views/backoffice/roteiro/RoteiroHome.vue'),
  },
  {
    path: '/pendencia',
    name: 'pendencia',
    component: () => import('@/views/backoffice/pendencia/PendenciaHome.vue'),
  },
  {
    path: '/enriquecimento',
    name: 'enriquecimento',
    component: () => import('@/views/backoffice/enriquecimento/EnriquecimentoHome.vue'),
  },
  // {
  //   path: '/discador',
  //   name: 'discador',
  //   component: () => import('@/views/cobranca/negociacao/components/Discador.vue'),
  //   // component: () => import('@/views/cobranca/boletos/BoletosHome.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  {
    path: '/Dashboard',
    name: 'meuDash',
    component: () => import('@/views/dashboard/GeneralDash.vue'),
  },
  {
    path: '/dashboard/remessas',
    name: 'dashRemessas',
    component: () => import('@/views/dashboardHome/dashRemessas.vue'),
  },
  {
    path: '/dashboard/faturamento',
    name: 'dashFaturamento',
    component: () => import('@/views/dashboardHome/dashFaturamento.vue'),
  },
  {
    path: '/dashboard/link-power-bi',
    name: 'linkBI',
    component: () => import('@/views/dashboardHome/ViewLinkBI.vue'),
  },
  {
    path: '/dashboard/metas',
    name: 'metas',
    component: () => import('@/views/dashboardHome/dashMetas.vue'),
  },
  {
    path: '/dashboard/metaDiaria',
    name: 'metasDiarias',
    component: () => import('@/views/rankingMetaDiaria/rankingMetaDiaria.vue'),
  },
  {
    path: '/administrador/cadastro',
    name: 'administrador.cadastro',
    component: () => import('@/views/admin/cadastro/CadastroHome.vue'),
  },
  {
    path: '/',
    name: 'administrativo',
    component: () => import('@/views/administrativo/DashboardAdministrativo.vue'),
  },
  {
    path: '/administrativo/cadastro',
    name: 'administrativo.cadastro',
    component: () => import('@/views/administrativo/cadastro/CadastroHome.vue'),
  },
  {
    path: '/administrativo/fornecedor',
    name: 'administrativo.fornecedor',
    component: () => import('@/views/administrativo/fornecedor/FornecedorHome.vue'),
  },
  {
    path: '/administrativo/fornecedor/:id',
    name: 'administrativo.contrato',
    component: () => import('@/views/administrativo/fornecedor/contrato/ContratoHome.vue'),
  },
  {
    path: '/financeiro',
    name: 'financeiro.dashboard',
    component: () => import('@/views/financeiro/DashboardFinanceiro.vue'),
  },
  {
    path: '/administrativo/templatesms',
    name: 'administrativo.templatesms',
    component: () => import('@/views/administrativo/templatesms/TemplatesmsHome.vue'),
  },
  {
    path: '/administrativo/templatemail',
    name: 'administrativo.templateemail',
    component: () => import('@/views/administrativo/templateemail/TemplateemailHome.vue'),
  },
  {
    path: '/administrativo/templatewhatsapp',
    name: 'administrativo.templatewhatsapp',
    component: () => import('@/views/administrativo/templatewhatsapp/TemplatewhatsappHome.vue'),
  },
  {
    path: '/financeiro/boletos',
    name: 'financeiro.boletos',
    component: () => import('@/views/financeiro/BoletosFinanceiro.vue'),
  },
  {
    path: '/financeiro/prestacao-de-contas',
    name: 'financeiro.prestacao',
    component: () => import('@/views/financeiro/prestacaoConta/PrestacaoDeConta.vue'),
  },
  {
    path: '/financeiro/negativacaocliente',
    name: 'financeiro.negativacaocliente',
    component: () => import('@/views/financeiro/NegativacaoHome.vue'),
  },
  {
    path: '/financeiro/operacoes/negativados/:id',
    name: 'financeiro.listnegativacao',
    component: () => import('@/views/financeiro/NegativacaoList.vue'),
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: () => import('@/views/usuario/UsuarioPerfil.vue'),
  },
  {
    path: '/configuracoes',
    name: 'configuracoes',
    component: () => import('@/views/usuario/UsuarioConfiguracao.vue'),
  },
  {
    path: '/credor',
    name: 'credor',
    component: () => import('@/views/credor/Dashboard/DashCredor.vue'),
  },
  {
    path: '/credor/cliente',
    name: 'credor.cliente',
    component: () => import('@/views/credor/clientes/ClienteCredorHome.vue'),
  },
  {
    path: '/credor/remessa',
    name: 'credor.remessa',
    component: () => import('@/views/credor/Remessa/Remessa.vue'),
  },
  {
    path: '/credor/remessa/view/:id',
    name: 'credor-remessa-view',
    component: () => import('@/views/credor/Remessa/RemessaView.vue'),
  },
  {
    path: '/credor/operacoes',
    name: 'credor.operacoes',
    component: () => import('@/views/credor/Operacoes/Operacoes.vue'),
  },
  {
    path: '/credor/campanha',
    name: 'credor.campanha',
    component: () => import('@/views/credor/Campanha/CredorCampanha.vue'),
  },
  {
    path: '/credor/acordos',
    name: 'credor.acordos',
    component: () => import('@/views/credor/acordos/AcordosCredor.vue'),
  },
  {
    path: '/credor/recebimentos',
    name: 'credor.recebimentos',
    component: () => import('@/views/credor/Relatorios/Recebimentos.vue'),
  },
  {
    path: '/credor/eventos',
    name: 'credor.eventos',
    component: () => import('@/views/credor/Relatorios/EventosCredor.vue'),
  },
  {
    path: '/credor/acoes/cobrancas',
    name: 'credor.cobrancas',
    component: () => import('@/views/credor/Relatorios/AcoesCobrancas.vue'),
  },
  // CRM ATUAL  EUDESRO
  {
    path: '/old/acordos',
    name: 'old.acordos',
    component: () => import('@/views/eudesro/acordos/AcordosHome.vue'),
  },
  // ROUTES REPORTS
  {
    path: '/relatorios/eventos',
    name: 'relatorio.eventos',
    component: () => import('@/views/relatorios/relatorioEventos.vue'),
  },
  {
    path: '/relatorios/contatos',
    name: 'relatorio.contato',
    component: () => import('@/views/relatorios/relatorioContato.vue'),
  },
  {
    path: '/relatorios/chamadas',
    name: 'relatorio.chamadas',
    component: () => import('@/views/relatorios/relatorioChamada.vue'),
  },
  {
    path: '/relatorios/operacoes',
    name: 'relatorio.operacoes',
    component: () => import('@/views/relatorios/relatorioOperacoes.vue'),
  },
  {
    path: '/relatorios/prestacao_contas',
    name: 'relatorio.prestacao_contas',
    component: () => import('@/views/relatorios/relatorioFaturamento.vue'),
  },
  {
    path: '/relatorios/acoes',
    name: 'relatorio.acoes',
    component: () => import('@/views/relatorios/AcompanhamentoAcoes.vue'),
  },
  {
    path: '/relatorios/devolucao',
    name: 'relatorio.devolucao',
    component: () => import('@/views/relatorios/relatorioDevolucao.vue'),
  },
  {
    path: '/relatorios/prestacao_contas_financeiro',
    name: 'relatorio.contas',
    component: () => import('@/views/relatorios/relatorioPrestacaoDeConta.vue'),
  },
  {
    path: '/relatorios/nao_acionados',
    name: 'relatorio.nao_acionados',
    component: () => import('@/views/relatorios/relatorioNaoAcionados.vue'),
  },
  {
    path: '/relatorios/extracao',
    name: 'relatorio.extracao',
    component: () => import('@/views/relatorios/relatorioExtracaoBase.vue'),
  },
  {
    path: '/relatorios/enriquecimento',
    name: 'relatorio.enriquecimento',
    component: () => import('@/views/relatorios/relatorioEnriquecimento.vue'),
  },
  {
    path: '/versoes',
    name: 'versoes',
    component: () => import('@/views/admin/cadastro/VersoesList.vue'),
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('@/views/apps/calendar/Calendar.vue'),
  },
]
const allowedCPFs = [
  '61037007360',
  '61097544397',
  '01076601219',
]

if (localStorage.userData) {
  const userData = JSON.parse(localStorage.userData)
  if (userData) {
    const { cpf } = userData
    allowedCPFs.map(item => {
      if (item === cpf) {
        routes.push(
          {
            path: '/integracao/rede-damas',
            name: 'integracao-rede-damas',
            component: () => import('@/views/financeiro/IntegracaoRedeDamas.vue'),
          },
          {
            path: '/integracao/rede-damas/:banco/:pagamento/:filial',
            name: 'view-remessa-aric',
            component: () => import('@/views/financeiro/ViewRemessaAric.vue'),
          },
        )
      }
    })
  }
}
export default routes
