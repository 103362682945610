import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  admin,
  // backoffice, cobranca, credor, administrativo, financeiro,
  // supervisaoCobranca,
} from './routes/index'

let routes = [
  {
    path: '/integracao/rede-damas',
    name: 'integracao-rede-damas',
    component: () => import('@/views/financeiro/IntegracaoRedeDamas.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/integracao/rede-damas/:banco/:pagamento',
    name: 'view-remessa-aric',
    component: () => import('@/views/financeiro/ViewRemessaAric.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/apps/chat',
    name: 'apps-chat',
    component: () => import('@/views/apps/chat/Chats.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'full',
    },
  },
]

routes = admin

Vue.use(VueRouter)

const token = localStorage.getItem('token')

routes = admin

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes,
})
if (!localStorage.userData) {
  localStorage.removeItem('token')
  localStorage.removeItem('expirationDate')
  localStorage.removeItem('userId')
  localStorage.removeItem('acesso_id')
  localStorage.removeItem('funcao')
  localStorage.removeItem('status')
  localStorage.removeItem('status_complemento')
  localStorage.removeItem('time_connected')
  localStorage.removeItem('phone_connected')
  localStorage.removeItem('cpf_connected')
  router.replace('/login')
}

router.beforeEnter = (to, from, next) => {
  if (token) {
    next()
  } else {
    next('/')
  }
}

// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
