import { addDays, addMonths } from 'date-fns'

import store from '@/store'
// import router from '@/router/index'

export default {
  namespaced: true,
  state: {
    alert: false,
    clienteInativo: false,
    credorSelected: null,
    operacoesCredor: [],
    operacoesSelected: [],
    descOp: false,
    descOpDisabled: false,
    negociacao: {
      nParcelas: 1,
      intervalo: 30,
      entrada: 0,
      juros: 0,
      multa: 0,
      honorarios: 0,
      desconto: 0,
      dataEntrada: '',
      dataRetro: '',
    },
    parametroDefault: null,
    parametroKey: '',
    parametroOptions: [],
    parametroCompleto: null,

    getRemuneracao: false,

    condicaoSelected: null,
    formaPagamento: null,

    honorariosChecked: false,
    honorariosCash: 0,

    descontoChecked: false,
    descontoCash: 0,

    isSimulacao: false,
    isBaixaCredor: false,

    totais: {
      totalNominal: 0,
      totalJuros: 0,
      totalMulta: 0,
      totalHonorario: 0,
      totalDesconto: 0,
      valorTotal: 0,
    },
  },
  mutations: {
    setAlert(state, data) {
      state.alert = data
    },
    setGetRemuneracao(state, data) {
      state.getRemuneracao = data
    },
    setnParcelas(state, data) {
      state.negociacao.nParcelas = data
    },
    setIntervalo(state, data) {
      state.negociacao.intervalo = data
    },
    setEntrada(state, data) {
      state.negociacao.entrada = data
    },
    setJuros(state, data) {
      state.negociacao.juros = data
    },
    setMulta(state, data) {
      state.negociacao.multa = data
    },
    setHonorarios(state, data) {
      state.negociacao.honorarios = data
    },
    setDesconto(state, data) {
      state.negociacao.desconto = data
    },
    setDataEntrada(state, data) {
      state.negociacao.dataEntrada = data
    },
    setDataRetro(state, data) {
      state.negociacao.dataRetro = data
    },
    setCredor(state, data) {
      state.credorSelected = data
    },
    setOperacoesCobranca(state, data) {
      state.operacoesCredor = data
    },
    setOperacoesSelected(state, data) { state.operacoesSelected = data },
    setTotais(state, data) { state.totais = data },
    setParametroDefault(state, data) { state.parametroDefault = data },
    setParametro(state, data) {
      Object.keys(data).map(key => {
        state[key] = data[key]
      })
    },
    setCondicao(state, data) { state.condicaoSelected = data },
    setFormaPagamento(state, data) { state.formaPagamento = data },
    setParamKey(state, data) {
      if (data.key && data.val) {
        state.negociacao[data.key] = data.val
      }
    },
    setParamKeyTeste(state, data) {
      data.map(item => {
        state.negociacao[item.key] = item.val
      })
    },
    setIsSimulacao(state, val) {
      state.isSimulacao = val
      if (!val) {
        store.commit('negociar/setCondicao', null)
      }
    },
    setIsBaixaCredor(state, val) { state.isBaixaCredor = val },
    setClienteInativo(state, data) {
      state.clienteInativo = data
    },
    setHonorariosChecked(state, data) {
      state.honorariosChecked = data
    },
    setHonorariosCash(state, data) {
      state.honorariosCash = data
    },
    setDescontoCash(state, data) { state.descontoCash = data },
    setDescontoChecked(state, data) { state.descontoChecked = data },
    setDescOp(state, data) { state.descOp = data },
    setDescOpDisabled(state, data) { state.descOpDisabled = data },
  },
  getters: {
    valorParcela: state => {
      let valorParcela = 0
      const { nParcelas, entrada } = state.negociacao
      const { formaPagamento } = state

      const { valorTotal: totalNegociado } = state.totais

      if (nParcelas > 1) {
        if (formaPagamento.key === 'cartao_voz' || formaPagamento.key === 'cartao_credor') {
          valorParcela = (totalNegociado) / (nParcelas)
        } else if (entrada > 0) {
          valorParcela = (totalNegociado - entrada) / (nParcelas - 1)
        } else {
          valorParcela = (totalNegociado) / (nParcelas)
        }
      } else {
        valorParcela = totalNegociado
      }

      return parseFloat(valorParcela).toFixed(2)
    },
    totalParcela: state => i => {
      const {
        entrada,
        nParcelas,
      } = state.negociacao
      const { formaPagamento } = state
      const { valorTotal } = state.totais
      const valorParcela = store.getters['negociar/valorParcela']

      if (i === 1) {
        if (nParcelas === 1 || nParcelas === '1') {
          return valorTotal
        }
        if (formaPagamento.key === 'boleto_pix') {
          if (parseFloat(entrada) !== 0) {
            return entrada
          }
        } else {
          return valorParcela
        }
      }

      let valorParcial = 0
      if (formaPagamento.key === 'boleto_pix') {
        if (parseFloat(entrada) > 0) {
          valorParcial = parseFloat(parseFloat(valorParcela).toFixed(2) * (nParcelas - 1)) + parseFloat(entrada)
        } else {
          valorParcial = parseFloat(parseFloat(valorParcela).toFixed(2) * (nParcelas))
        }
      } if (formaPagamento.key === 'cartao_voz' || formaPagamento.key === 'cartao_credor' || formaPagamento.key === 'maquineta') {
        valorParcial = parseFloat(parseFloat(valorParcela).toFixed(2) * (nParcelas))
      }

      if (parseFloat(valorParcial) === parseFloat(valorTotal)) {
        return valorParcela
      }
      const diff = (
        parseFloat(valorTotal) - parseFloat(valorParcial)
      ).toFixed(2)

      if (i === parseInt(nParcelas)) {
        const resp = parseFloat(valorParcela) + parseFloat(diff)
        return resp.toFixed(2)
      }
      return parseFloat(valorParcela)
    },
    setDataVencimento: state => i => {
      const {
        dataEntrada,
        intervalo,
      } = state.negociacao
      const a = dataEntrada.split('-')
      let b = addDays(
        new Date(a[0], parseInt(a[1]) - 1, a[2]),
        i !== 1 ? parseFloat(intervalo) * (i - 1) : 0,
      ).toLocaleString('en-US')
      if (intervalo === '30' || intervalo === 30) {
        b = addMonths(
          new Date(a[0], parseInt(a[1]) - 1, a[2]),
          i - 1,
        ).toLocaleString('en-US')
      }
      const c = b.split(',')[0].split('/')
      return `${c[2]}-${c[0]}-${c[1]}`
    },
    getBodyAcordo: state => async (adm = false) => {
      // const { negociar: state } = st
      const {
        operacoesSelected: itemSelected,
        formaPagamento: obj,
        credorSelected,
        clienteInativo,
        parametroCompleto,
        parametroDefault,
      } = state

      // eslint-disable-next-line camelcase
      const { executivo_id: executivo_cobranca_id } = localStorage

      const {
        totalJuros,
        totalMulta,
        totalDesconto,
        totalHonorario,
        valorTotal: totalNegociado,
      } = state.totais
      const {
        entrada,
        nParcelas,
        juros,
        multa,
        honorarios,
        desconto,
      } = state.negociacao

      const totalParcela = i => store.getters['negociar/totalParcela'](i)
      const setDataVencimento = i => store.getters['negociar/setDataVencimento'](i)
      const valorParcela = store.getters['negociar/valorParcela']

      const mapeamentoFormaPagamento = {
        cartao_credor: 'link_credor',
        cartao_voz: 'link_voz',
      }

      const formaPagamento = mapeamentoFormaPagamento[obj.key] || obj.key
      const cliente = store.state.negociacao.infoCliente

      const filterCondition = state.isBaixaCredor
        ? e => parseFloat(e.diasVenc) >= 0
        : e => parseFloat(e.diasVenc) < credorSelected.prazo_honorario && parseFloat(e.diasVenc) >= 0

      const operacoesComissionadas = itemSelected.filter(filterCondition)
      const totalOperacoesComissionadas = operacoesComissionadas.reduce((counter, item) => counter + parseFloat(item.total), 0)

      const calculoComissao = d => {
        if (((state.isBaixaCredor && d >= 0))) {
          const comissaoFixaFormatada = parseFloat(parametroDefault.comissao_fixa) / 100
          const comissaoFixaBaixaCredorFormatada = parseFloat(parametroDefault.comissao_baixa_credor) / 100
          const tipoComissao = d <= credorSelected.prazo_honorario ? comissaoFixaFormatada : comissaoFixaBaixaCredorFormatada
          const comissaoPercent = tipoComissao
          const comissao = (comissaoPercent * totalOperacoesComissionadas).toFixed(2)
          return { comissao, percent: comissaoPercent * 100 }
        } if (parametroCompleto.remuneracao.key !== 'honorario') {
          const condicao = parseInt(nParcelas) !== 1 ? 'parcelado' : 'avista'
          const parametro = parametroCompleto[condicao][obj.key]
          const comissaoPercent = parseFloat(parametro.remuneracao_max) / 100
          const comissao = parseFloat((comissaoPercent * totalNegociado).toFixed(2))
          return { comissao, percent: comissaoPercent * 100 }
        }
        if (((d < credorSelected.prazo_honorario && d >= 0))) {
          const comissaoPercent = parseFloat(parametroDefault.comissao_fixa) / 100
          const comissao = (comissaoPercent * totalOperacoesComissionadas).toFixed(2)
          return { comissao, percent: comissaoPercent * 100 }
        }
        return { comissao: 0 }
      }

      const getParcelaEncargos = (total, i) => {
        const percent = (parseFloat(entrada) / parseFloat(totalNegociado)).toFixed(2)
        const valor = parseFloat(percent) * parseFloat(total)
        if (i === 1) {
          if (nParcelas === 1 || nParcelas === '1') {
            return total
          }
          if (parseFloat(entrada) !== 0) {
            return valor.toFixed(2)
          }
        }
        const valorParc = parseFloat(nParcelas) > 1 && parseFloat(percent) === 0 ? ((parseFloat(total)) / (nParcelas)).toFixed(2) : ((parseFloat(total) - valor) / (nParcelas - 1)).toFixed(2)

        const valorParcial = nParcelas > 1 && parseFloat(entrada) === 0 ? parseFloat(parseFloat(valorParc) * (nParcelas)) : parseFloat(parseFloat(valorParc) * (nParcelas - 1)) + parseFloat(valor.toFixed(2))
        if (parseFloat(valorParcial) === parseFloat(total)) {
          return valorParc
        }

        const diff = (
          parseFloat(total) - parseFloat(valorParcial)
        ).toFixed(2)

        if (i === parseInt(nParcelas)) {
          const resp = parseFloat(valorParc) + parseFloat(diff)
          return resp.toFixed(2)
        }
        return valorParc
      }

      function dataFormatDB(data) {
        const a1 = data.split('/')
        return `${a1[2]}-${a1[1]}-${a1[0]}`
      }

      const operacoes = itemSelected.map(item => {
        const operacoesComissionadasLength = parametroCompleto.remuneracao.key !== 'honorario' ? itemSelected.length : operacoesComissionadas.length
        return {
          id: item.id,
          valor_juros: item.taxaJuros,
          valor_multa: item.taxaMulta,
          valor_atualizado: item.total,
          valor_honorario: item.taxaHonorarios,
          valor_comissao: parseFloat((parseFloat(calculoComissao(item.diasVenc).comissao) / parseFloat(operacoesComissionadasLength)).toFixed(2)) || 0,
          data_atualizacao: setDataVencimento(1),
          valor_desconto: item.taxaDesconto,
          data_vencimento: dataFormatDB(item.data_vencimento),
          numero_operacao: item.numero_operacao,
          aluno: item.aluno,
          descricao: item.descricao,
          valor_nominal: item.valor_nominal,
        }
      })

      const comissao = operacoes.reduce((accumulator, object) => accumulator + object.valor_comissao, 0)
      const totalNegociadoOperacoes = operacoes
        .filter(item => item.valor_comissao !== 0)
        .reduce((accumulator, object) => accumulator + parseFloat(object.valor_atualizado), 0)

      const parcelas = []
      const parcelasToOperacoes = []
      for (let i = 1; i <= nParcelas; i++) {
        parcelas.push({
          // eslint-disable-next-line no-nested-ternary
          valor_nominal: totalParcela(i),
          data_vencimento: setDataVencimento(i),
          vencimento_original: setDataVencimento(i),
          numero_parcela: i,
          valor_juros: 0,
          valor_multa: 0,
          valor_honorario: 0,
          valor_desconto: 0,
          forma_pagamento: state.isBaixaCredor ? 'Credor' : formaPagamento,
          valor_comissao: 0,
          valor_nominal_original: totalParcela(i),
          status: 'A Vencer',
          data_credor_informou: state.isBaixaCredor ? state.negociacao.dataRetro : null,
        })

        parcelasToOperacoes.push({
          // eslint-disable-next-line no-nested-ternary
          valor_nominal: totalParcela(i),
          data_vencimento: setDataVencimento(i),
          vencimento_original: setDataVencimento(i),
          numero_parcela: i,
          valor_juros: getParcelaEncargos(totalJuros, i),
          valor_multa: getParcelaEncargos(totalMulta, i),
          valor_honorario: getParcelaEncargos(totalHonorario, i),
          valor_desconto: getParcelaEncargos(totalDesconto, i),
          forma_pagamento: state.isBaixaCredor ? 'Credor' : formaPagamento,
          valor_comissao: getParcelaEncargos(comissao, i),
          valor_nominal_original: totalParcela(i),
        })
      }

      const comissaoPercent = comissao !== 0
        ? ((comissao / totalNegociadoOperacoes) * 100).toFixed(2)
        : 0
      const acordo = {
        status: state.isBaixaCredor ? 'Baixa Administrativa' : 'Aguardando',
        valor_nominal: totalNegociado,
        juros: totalJuros,
        multas: totalMulta,
        honorarios: totalHonorario,
        desconto: totalDesconto,
        valor_entrada: entrada,
        quantidade_parcelas: nParcelas,
        valor_parcela: valorParcela,
        cliente_id: null,
        credor_id: credorSelected.id,
        // eslint-disable-next-line camelcase
        executivo_cobranca_id: adm ? process.env.VUE_APP_ROOT_ADM : executivo_cobranca_id,
        juros_porcento: juros,
        multas_porcento: multa,
        honorarios_porcento: honorarios,
        desconto_porcento: desconto,
        comissao,
        comissao_porcento: comissaoPercent,
        situacao: clienteInativo ? 'INATIVO' : 'ATIVO',
        data_acordo_original: state.isSimulacao ? state.dataRetro : null,
      }

      const setParcelasOperacoes = () => {
        const percent = parseFloat(entrada) / parseFloat(totalNegociado)
        const parcelasJson = []
        const getParcelas = (valor, indexParcela) => {
          if (percent === 0 && nParcelas === 1) {
            return valor
          } if (percent === 0 && nParcelas > 1) {
            const valorOperacao = ((parseFloat(valor)) / (nParcelas)).toFixed(2)
            return valorOperacao
          }
          const valorEntrada = (parseFloat(valor) * percent).toFixed(2)
          if (indexParcela === 0) {
            return valorEntrada
          }
          const valorOperacao = ((parseFloat(valor) - valorEntrada) / (nParcelas - 1)).toFixed(2)
          return valorOperacao
        }
        parcelasToOperacoes.map((p, i) => {
          const somaAtualizado = {}
          operacoes.map((o, io) => {
            let multaRat = 0
            let jurosRat = 0
            let honorariosRat = 0
            let comissaoRat = 0
            let descontoRat = 0
            let realizado = 0
            const opKeys = {
              valor_atualizado: 'valor_nominal',
              valor_juros: 'valor_juros',
              valor_multa: 'valor_multa',
              valor_honorario: 'valor_honorario',
              valor_desconto: 'valor_desconto',
              valor_comissao: 'valor_comissao',
            }
            Object.keys(opKeys).map(opK => {
              const valorOperacao = o[opK]
              let valor = getParcelas(valorOperacao, i)
              if (!somaAtualizado[opK]) {
                somaAtualizado[opK] = 0
              }
              const valorEntrada = p[opKeys[opK]]
              if (operacoes.length === io + 1) {
                if (somaAtualizado[opK] + parseFloat(valor) !== parseFloat(valorEntrada)) {
                  const diff = parseFloat(valorEntrada) - parseFloat(somaAtualizado[opK] + parseFloat(valor))
                  valor = (parseFloat(valor) + parseFloat(diff)).toFixed(2)
                }
                if (opK === 'valor_comissao') {
                  const formatSomaAtualizada = parseFloat(somaAtualizado[opK])
                  const diferenca = (parseFloat(valorEntrada) - formatSomaAtualizada).toFixed(2)
                  const somaAtual = parseFloat(formatSomaAtualizada) + parseFloat(diferenca)
                  const somaAtualFormatada = somaAtual.toFixed(2)

                  if (somaAtualFormatada !== valorEntrada) {
                    comissaoRat = (parseFloat(valorEntrada) - formatSomaAtualizada).toFixed(2)
                    return
                  }
                }
              }
              if (opK !== 'valor_comissao') {
                somaAtualizado[opK] += parseFloat(valor)
              }
              switch (opK) {
                case 'valor_atualizado':
                  realizado = valor
                  break
                case 'valor_juros':
                  jurosRat = valor
                  break
                case 'valor_multa':
                  multaRat = valor
                  break
                case 'valor_honorario':
                  honorariosRat = valor
                  break
                case 'valor_desconto':
                  descontoRat = valor
                  break
                case 'valor_comissao':
                  if (o[opK] !== 0) {
                    comissaoRat = ((parseFloat(realizado) * comissaoPercent) / 100).toFixed(2)
                    somaAtualizado[opK] += (parseFloat(realizado) * comissaoPercent) / 100
                    break
                  }
                  break
                default:
                  break
              }
            })
            const principal = (parseFloat(realizado) + parseFloat(descontoRat) - parseFloat(jurosRat) - parseFloat(multaRat) - parseFloat(honorariosRat)).toFixed(2)
            // eslint-disable-next-line no-unused-vars, camelcase
            const valor_repasse = clienteInativo ? principal : (parseFloat(realizado) - parseFloat(comissaoRat) - parseFloat(honorariosRat)).toFixed(2)
            const objectOp = {
              indice: i + 1,
              numero_operacao: o.numero_operacao,
              operacao_id: o.id,
              instituicao: credorSelected.nome,
              credor_id: credorSelected.id,
              cliente: cliente.nome,
              aluno: o.aluno,
              documento: cliente.cpf_cnpj,
              vencimento_original: o.data_vencimento,
              vencimento: p.data_vencimento,
              recebimento: null,
              parcela: `${i + 1}/${nParcelas}`,
              valor_negociado: totalNegociado,
              juros: jurosRat,
              multa: multaRat,
              honorarios: honorariosRat,
              desconto: descontoRat,
              realizado,
              principal,
              valor_repasse,
              origem: o.descricao,
              correcao: 0,
              taxa_cartao: 0,
              comissao: comissaoRat,
              forma: formaPagamento,
            }
            parcelasJson.push(objectOp)
          })
        })
        return parcelasJson
      }

      const findIndexSemComissao = parc => {
        for (let i = 0; i < parc.length; i++) {
          if (parc[i].comissao === 0) {
            return i
          }
        }
        return -1
      }
      const findIndexComComissao = parc => {
        for (let i = 0; i < parc.length; i++) {
          if (parc[i].comissao > 0) {
            return i
          }
        }
        return -1
      }
      // essa funcao tem como objetivo verificar se tem uma operacao_parcela com honorario e comissao e ajusta-la
      const checkOperacoesParcelas = () => {
        const operacoesParcelas = setParcelasOperacoes()
        const operacoesParcelasFixHonorarioEComissao = operacoesParcelas.map((item, index) => {
          if ((item.honorarios > 0 || item.honorarios < 0) && item.comissao > 0) {
            const valorHonorario = parseFloat(item.honorarios)
            const indexSemComissao = findIndexSemComissao(operacoesParcelas)

            if (indexSemComissao !== -1) {
              operacoesParcelas[index].honorarios = 0
              operacoesParcelas[indexSemComissao].honorarios = valorHonorario + parseFloat(operacoesParcelas[indexSemComissao].honorarios)
            }
          }
          return item
        })
        let somaComissoes = 0
        return operacoesParcelasFixHonorarioEComissao.map((item, index) => {
          somaComissoes += parseFloat(item.comissao)
          const valorComissaoTotal = parseFloat(acordo.comissao)
          const indexComComissao = findIndexComComissao(operacoesParcelas)

          if ((valorComissaoTotal !== somaComissoes) && index === operacoesParcelasFixHonorarioEComissao.length - 1) {
            const diferenca = valorComissaoTotal - somaComissoes
            if (indexComComissao !== 1) {
              operacoesParcelas[indexComComissao].comissao = (parseFloat(operacoesParcelas[indexComComissao].comissao) + diferenca).toFixed(2)
            }
          }
          return item
        })
      }
      const body = {
        simulacao: false,
        acordo,
        parcelas,
        operacoes,
        parametros_contato: '',
        parcelasOperacoes: checkOperacoesParcelas(),
        formaPagamento: state.formaPagamento,
      }
      return body
    },
  },
  actions: {
    setOperacoesCobranca({ commit }) {
      function diasEducaMais(dataInicio) {
        const dtv = dataInicio.split('/')
        const dataV = `${dtv[0]}/${dtv[1]}/${dtv[2]}`
        const timeElapsed = Date.now()
        const today = new Date(timeElapsed)
        const dt = today.toISOString()
        const dt2 = dt.split('T')
        const dt3 = dt2[0].split('-')
        const dataE = `${dt3[1]}/${dt3[2]}/${dt3[0]}`
        const dataInicialMilissegundos = new Date(dataV).getTime()
        const dataFinalMilissegundos = new Date(dataE).getTime()
        const umDiaMilissegundos = 1000 * 60 * 60 * 24
        const diferencaMilissegundos = dataFinalMilissegundos - dataInicialMilissegundos
        const diferencaData = Math.round(
          diferencaMilissegundos / umDiaMilissegundos,
        )
        return diferencaData
      }

      const DOM_BOSCO_LIST = [129, 130, 133]
      const isDomBosco = store.state.negociacao.credores.filter(credor => DOM_BOSCO_LIST.includes(credor.id)).length > 0

      const operacoes = store.getters['negociacao/listOperacoesCobranca'].filter(item => {
        if (isDomBosco) {
          return item
        }
        return item.credor.id === store.state.negociar.credorSelected.id
      })
      commit('setOperacoesCobranca', operacoes)
      operacoes.map(item => {
        if (item.adicionais) {
          const aux = item.adicionais
          const dataInicio = JSON.parse(aux)

          if (dataInicio.data_inicio) {
            const diasInicioEducaMais = diasEducaMais(dataInicio.data_inicio)
            if (diasInicioEducaMais > 540) {
              commit('setClienteInativo', true)
            }
          }
        }
      })
    },
    // eslint-disable-next-line no-unused-vars
    setParamKey({ commit }, { paramsKeys, totalNegociado, verifyEntrada }) {
      if (paramsKeys) {
        const { parametroCompleto } = store.state.negociar
        const excludes = ['nParcelas', 'label', 'avaible', 'desconto_concedido']

        const obj = Object.keys(paramsKeys).filter(e => !excludes.includes(e) && !e.includes('_max'))
        // eslint-disable-next-line consistent-return
        const pKeys = obj.map(key => {
          switch (key) {
            case 'remuneracao': {
              const { remuneracao } = parametroCompleto
              if (remuneracao.key === 'honorario') {
                return {
                  key: 'honorarios',
                  val: paramsKeys.remuneracao_max,
                }
              }
              break
            }
            case 'desconto':
              return {
                key: 'desconto',
                val: paramsKeys.desconto,
              }
            case 'entrada': {
              if (verifyEntrada) {
                return {
                  key: 'entrada',
                  val: 0,
                }
              }
              break
            }
            default:
              return {
                key,
                val: paramsKeys[`${key}_max`],
              }
          }
        })

        commit('setParamKeyTeste', pKeys.filter(Boolean))
      }
    },
  },
}
