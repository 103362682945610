/* eslint-disable no-unused-vars */
import api from '@/../axios-auth'
import store from '@/store'

export default {
  namespaced: true,
  state: {
    infoCliente: {},
    operacoes: [],
    acordos: [],
    credores: [],
    emails: [],
    telefones: [],
    propostas: [],
    parcelasAcordo: [],
    parcelasToUpdate: [],
    parcelasSelected: [],
    attParcerlaMessage: '',
    attParcerlaBar: {
      variant: 'success',
      value: 0,
    },
  },
  getters: {
    listOperacoesCobranca(state) {
      const { operacoes } = state
      const operacoesEmCobranca = operacoes.map(item => {
        if (item.status_operacao.sigla === 'C') {
          return item
        }
        return null
      })
      return operacoesEmCobranca.filter(e => e !== null)
    },
  },
  mutations: {
    setAttParcelaProgess(state, data) {
      state.attParcerlaMessage = data.message
      state.attParcerlaBar.value = data.value
    },
    setParcelasAcordo(state, data) {
      state.parcelasAcordo = data
    },
    setParcelasToUpdate(state, data) {
      state.parcelasToUpdate = data
    },
    setParcelasSelected(state, data) {
      state.parcelasSelected = data
    },
    setInfoCliente(state, data) {
      state.infoCliente = data
    },
    setOperacoes(state, data) {
      state.operacoes = data
    },
    setAcordos(state, data) {
      state.acordos = data
    },
    setCredores(state, data) {
      state.credores = data
    },
    setEmails(state, data) {
      state.emails = data
    },
    setTelefones(state, data) {
      state.telefones = data
    },
    setPropostas(state, data) {
      state.propostas = data
    },
  },
  actions: {
    async getInfoCliente({ commit }, id) {
      await api.get(`api/v1/acordo/negociacao/${id}`).then(res => {
        const { info } = res.data.dados
        const {
          // Info do cliente
          bairro,
          cep,
          cidade,
          cpf_cnpj,
          endereco,
          nome,
          observacoes,
          perfil,
          roteiro,
          // Informacoes gerais
          acordos,
          eventos,
          operacoes,
          credores,
          numero,
          localizacao,
          classificacao_cliente,
          emails,
          telefones,
        } = info.cliente

        const infoCliente = {
          id,
          bairro,
          cep,
          cidade,
          cpf_cnpj,
          endereco,
          numero,
          nome,
          observacoes,
          localizacao,
          classificacao_cliente,
          perfil,
          roteiro,
        }

        commit('setInfoCliente', infoCliente)
        commit('setOperacoes', operacoes)
        commit('setAcordos', acordos)
        commit('setCredores', credores)
        commit('setEmails', emails.map(email => email.email))
        commit('setTelefones', telefones.map(telefone => telefone.telefone))
        store.commit('eventos/updateEvents', eventos)
      })

      commit('setPropostas', await api.get(`api/v1/proposta/listar/cliente/${id}`).then(res => res.data.dados.reverse()))
    },
  },
}
