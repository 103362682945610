import { $themeBreakpoints } from '@themeConfig'
import firebase from '../../../firebase'

const previousDay = new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
const dayBeforePreviousDay = new Date(new Date().getTime() - 24 * 60 * 60 * 1000 * 2)

export default {
  namespaced: true,
  state: {
    chats: {
      id: 1,
      user: 'Jairo Rodrigues',
    },
    teste: [],
    data: {
      profileUser: {
        id: 11,
        avatar: '1',
        fullName: 'Administrador',
        role: 'admin',
        about:
          'Orgulho de ser VOZ',
        status: 'online',
        settings: {
          isTwoStepAuthVerificationEnabled: true,
          isNotificationsOn: false,
        },
      },
      contacts: [
        {
          id: 1,
          fullName: 'Eudes Rodrigues',
          role: 'Developer',
          about: 'Orgulho de ser VOZ',
          avatar: '',
          status: 'offline',
        },
        {
          id: 2,
          fullName: 'Jairo Rodrigues',
          role: 'UI/UX Designer',
          about:
            'Orgulho de ser VOZ',
          avatar: '',
          status: 'busy',
        },
        {
          id: 3,
          fullName: 'Rafael Gigante',
          role: 'Town planner',
          about:
            'Orgulho de ser VOZ',
          avatar: '',
          status: 'busy',
        },
        {
          id: 4,
          fullName: 'Richard Mendonça',
          role: 'Data scientist',
          about:
            'Orgulho de ser VOZ',
          avatar: '',
          status: 'online',
        },
        {
          id: 5,
          fullName: 'Welliton Cunha',
          role: 'Dietitian',
          about: 'Orgulho de ser VOZ',
          avatar: '',
          status: 'busy',
        },
        {
          id: 6,
          fullName: 'Ricardo Ítalo',
          role: 'Marketing executive',
          about:
            'Orgulho de ser VOZ',
          avatar: '',
          status: 'online',
        },
        {
          id: 7,
          fullName: 'Hadassa Castro',
          role: 'Orgulho de ser VOZ',
          about:
            'Biscuit powder oat cake donut brownie ice cream I love soufflé. I love tootsie roll I love powder tootsie roll.',
          avatar: '',
          status: 'online',
        },
        {
          id: 8,
          fullName: 'Nathila Rodrigues',
          role: 'Orgulho de ser VOZ',
          about:
            'Bear claw ice cream lollipop gingerbread carrot cake. Brownie gummi bears chocolate muffin croissant jelly I love marzipan wafer.',
          avatar: '',
          status: 'away',
        },
        {
          id: 9,
          fullName: 'Nonilton Alves',
          role: 'Maneger Projet',
          about:
            'Orgulhor de ser VOZ',
          avatar: '',
          status: 'offline',
        },
        {
          id: 10,
          fullName: 'Igor',
          role: 'Building surveyor',
          about: 'Orgulho de ser VOZ',
          avatar: '',
          status: 'away',
        },
      ],
      chats: [
        {
          id: 1,
          userId: 2,
          unseenMsgs: 0,
          chat: [
            {
              message: 'Olá',
              time: 'Mon Dec 10 2018 07:45:00 GMT+0000 (GMT)',
              senderId: 11,
            },
            {
              message: 'Oi',
              time: 'Mon Dec 11 2018 07:45:15 GMT+0000 (GMT)',
              senderId: 2,
            },
            {
              message: 'Testanto Chat',
              time: 'Mon Dec 11 2018 07:46:10 GMT+0000 (GMT)',
              senderId: 11,
            },
            {
              message: 'ok',
              time: dayBeforePreviousDay,
              senderId: 11,
            },
          ],
        },
        {
          id: 2,
          userId: 1,
          unseenMsgs: 1,
          chat: [
            {
              message: 'oi',
              time: 'Mon Dec 10 2018 07:45:00 GMT+0000 (GMT)',
              senderId: 11,
            },
            {
              message: 'oi',
              time: 'Mon Dec 10 2018 07:45:23 GMT+0000 (GMT)',
              senderId: 1,
            },
            {
              message: '👍',
              time: previousDay,
              senderId: 1,
            },
          ],
        },
        {
          id: 3,
          userId: 3,
          unseenMsgs: 1,
          chat: [
            {
              message: 'Oi',
              time: 'Mon Dec 10 2018 07:45:00 GMT+0000 (GMT)',
              senderId: 11,
            },
            {
              message: 'funfou 👍',
              time: previousDay,
              senderId: 3,
            },
          ],
        },
      ],
    },
    windowWidth: 0,
    shallShowOverlay: false,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    setChats(state, payload) {
      state.chats = payload
    },
    SET_CHAT_DATA(state, payload) {
      state.teste = payload
    },
  },
  actions: {
    fetchChats({ commit }) {
      firebase.ref('teste/1').once('value', snap => {
        commit('SET_CHAT_DATA', snap.val())
      })
    },
  },
}
